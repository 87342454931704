import React, { useCallback, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toolbar } from 'primereact/toolbar';
import { Avatar } from 'primereact/avatar';
import { useHistory } from 'react-router-dom';
import { useAuthAxios, authenticatedAxios } from '../Service/auth';
import { AxiosPromise } from 'axios';
import { Messages } from 'primereact/messages';

const borderStyle = { minWidth: '350px', width: "calc(100vw - 30px)" };

function release(userId: string, bucketKey: string): AxiosPromise {
    return authenticatedAxios.request({
        method: "POST",
        url: `/api/receipt/${userId}/${bucketKey}/release`
    });
};

function take(userId: string, bucketKey: string): AxiosPromise {
    return authenticatedAxios.request({
        method: "POST",
        url: `/api/receipt/${userId}/${bucketKey}/take`
    });
};


function Download() {
    const messagesRef = useRef<Messages>(null);
    const history = useHistory();
    const [{ data, loading }, refetch] = useAuthAxios({
        url: "/api/receipt/request",
        method: 'GET'
    }, {
        useCache: false
    });

    const cancel = useCallback(() => {
        release(data.userId, data.bucketKey).finally(() => {
            history.push("/");
        });
        history.push("/");
    }, [history, data]);

    useEffect(() => {
        let timerId = setTimeout(() => {
            cancel();
        }, 90000);

        return () => clearTimeout(timerId);
    }, [cancel]);

    const download = useCallback(() => {
        // TODO : iOS nem nyitja meg az ablakot, csak onclickbol...
        window.open(data.url, "_blank");
        take(data.userId, data.bucketKey).then(() => {
            setTimeout(() => {
               history.push("/"); 
            });
        }).catch((r) => {
            messagesRef.current!.show({ severity: 'error', detail: 'Unable to upload' });
            console.log(r);
        });
    }, [data, history]);

    return (data?.url ? <>
        <Messages ref={messagesRef}></Messages>
        <div className="p-col">
            <div className="p-grid p-dir-col">

                <Card style={borderStyle}>
                    <ScrollPanel style={{ height: "calc(100vh - 350px)" }}>
                        <img src={data.url} alt="example" />
                    </ScrollPanel>
                </Card>
            </div>

            <div className="p-col">
                <Toolbar
                    left={
                        <Avatar onClick={download}
                            icon="pi pi-download" shape="circle" size="large" className="p-mr-2"
                            style={{ backgroundColor: 'var(--green-300)' }} />
                    }
                    right={
                        <Avatar onClick={cancel}
                            icon="pi pi-times" shape="circle" size="large" className="p-mr-2" />
                    }
                />
            </div>
        </div>
    </>
        :
        loading ? <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i> :
            <>
                <Avatar onClick={() => refetch()}
                    icon="pi pi-refresh" shape="circle" size="large" className="p-mr-2"
                    style={{ backgroundColor: 'var(--green-300)' }} />
            </>);
}

export default Download;
