import React, { useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

import { authenticatedAxios } from './Service/auth';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './App.css';

import Home from "./Pages/Home";
import Upload from "./Pages/Upload";
import Download from "./Pages/Download";

function App() {
  return (
    <>
      <Router>
        <div className="p-m-2">
          <Toolbar left={
            <>
              <Link to="/home"><Button icon="pi pi-home" className="p-mr-2" /></Link>
              <Link to="/upload"><Button label="Upload" icon="pi pi-cloud-upload" className="p-mr-2" /></Link>
              <Link to="/download"><Button label="Get one" icon="pi pi-cloud-download" className="p-mr-2" /></Link>
            </>}>
          </Toolbar>
          <Switch>
            <Route exact path="/upload" component={Upload} />
            <Route exact path="/download" component={Download} />
            <Route component={Home} />
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
