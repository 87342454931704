import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { readAndCompressImage } from 'browser-image-resizer';
import { Card } from 'primereact/card';
import { FileUpload } from 'primereact/fileupload';
import { Knob } from 'primereact/knob';
import { Messages } from 'primereact/messages';
import React, { useCallback, useRef, useState } from 'react';
import { useAuthAxios, authenticatedAxios } from '../Service/auth';

const config = {
    quality: 0.5,
    maxWidth: 1200,
    maxHeight: 1200,
    autoRotate: true,
    debug: true
};

function registerUpload(userId: string, bucketKey: string): AxiosPromise {
    return authenticatedAxios.request({
        method: "PUT",
        url: `/api/receipt/${userId}/${bucketKey}`
    });
};

function Upload() {
    const uploadRef = useRef<any>(null);
    const messagesRef = useRef<Messages>(null);
    const [progress, setProgress] = useState<number | null>(null);
    const upload = useCallback((e) => {
        setProgress(5);
        readAndCompressImage(e.files[0], config)
            .then((resizedImage: Blob) => {
                let upload = {
                    userId: "null",
                    bucketKey: "null"
                };
                setProgress(10);
                authenticatedAxios.get("/api/users/uploadToken")
                    .then(data => {
                        const req: AxiosRequestConfig = data.data;
                        req.data = resizedImage;
                        req.onUploadProgress = (progressEvent) => {
                            const totalLength = progressEvent.lengthComputable ? progressEvent.total : null;
                            if (totalLength !== null) {
                                setProgress(10 + Math.round((progressEvent.loaded * 85) / totalLength));
                            }
                        }

                        upload.userId = data.data.userId;
                        upload.bucketKey = data.data.bucketKey;

                        return axios.request(req);
                    })
                    .then((r) => {
                        if (r) {
                            return registerUpload(upload.userId, upload.bucketKey);
                        }
                    })
                    .then((r) => {
                        if (r) {
                            //uploadRef.current!.clear();
                            messagesRef.current!.show({ severity: 'success', detail: 'Successful upload' });
                        }
                    })
                    .catch((e) => {
                        messagesRef.current!.show({ severity: 'error', detail: 'Communication error' });
                        console.log(e);
                    })
                    .finally(() => {
                        setProgress(0);
                    });

            });
    }, [setProgress]);

    return (<>
        <Messages ref={messagesRef}></Messages>
        <div className="p-grid p-dir-col">
            <div className="p-col-12 p-xs-12">
                <Card>
                    {progress 
                    ? 
                    <Knob value={progress} min={0} max={100} /> 
                    : 
                    <FileUpload
                        ref={uploadRef}
                        auto mode="basic" customUpload
                        uploadHandler={upload}
                        accept="image/*" maxFileSize={4000000} />}
                </Card>
            </div>
        </div>
    </>
    );
}

export default Upload;
