import React from 'react';
import { Card } from 'primereact/card';

import { UserData } from 'react-oidc';

import { useAuthAxios } from "../Service/auth";

function Home() {
    const [{ data, loading }] = useAuthAxios({
        url: "/api/users/me",
        method: 'GET'
    });

    return (<div className="p-grid p-dir-col">
        <div className="p-col-12 p-xs-12">
            <Card>
                <UserData.Consumer>
                    {context => context.user!.profile.preferred_username}
                </UserData.Consumer>
            </Card>
            {loading ? <span>Loading...</span> :
                <Card>
                    <pre>{JSON.stringify(data)}</pre>
                </Card>
            }
        </div>
    </div>);
}

export default Home;
