import { makeUserManager } from "react-oidc";
import { User, WebStorageStateStore } from 'oidc-client';
import { useEffect, useState } from "react";

import {  makeUseAxios } from "axios-hooks";

import Axios from "axios";

export const userManager = makeUserManager({
  loadUserInfo: true,
  userStore: new WebStorageStateStore({
    store: sessionStorage
  }),
  authority: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_pzhgUWyvt/",
  client_id: "5jc1do4jgnrics1boqfktkgcsc",
  redirect_uri: window.location.origin,
  response_type: "code",
  automaticSilentRenew: true,
  silent_redirect_uri: window.location.origin + "/silentRefresh",
  scope: "email openid profile", // add other scopes here
});

export const authenticatedAxios = Axios.create({
  timeout: 10000,
  baseURL: process.env.REACT_APP_BACKEND,
});

authenticatedAxios.interceptors.request.use((config) => {
  return userManager.getUser().then(u => {
    config.headers.Authorization = 'Bearer ' + u!.access_token;
    return Promise.resolve(config);
  });
});

export const useAuthAxios = makeUseAxios({ 
  axios: authenticatedAxios
 });

export function useUsername(): User | null {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    userManager.getUser().then(u => {
      setUser(u);
    })
  }, []);

  return user;
};