import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { makeAuthenticator } from "react-oidc";
import { userManager } from './Service/auth';

const ProtectRoute = makeAuthenticator({
  userManager: userManager
});

const ProtectedApp = ProtectRoute(App);

if (window.location.href.indexOf("/silentRefresh") !== -1) {
  userManager.signinSilentCallback()
    .catch((err) => {
      console.log(err);
    });
} else if (window.location.href.indexOf("?") !== -1) {
  userManager.signinRedirectCallback(window.location.href).then(() => {
    window.location.href = window.location.href
      .substring(0, window.location.href.indexOf("?") - 1);
  });
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ProtectedApp />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
